@use "@angular/material" as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.core();

// https://material.io/design/color/
$client-primary: mat.define-palette(mat.$brown-palette);
$client-accent: mat.define-palette(
  mat.$deep-orange-palette,
  A200,
  A100,
  A400,
  A700
);
$client-warn: mat.define-palette(mat.$red-palette);
$client-background: mat.define-palette(mat.$cyan-palette);

$custom-font-family: 'Open Sans', sans-serif;
$custom-title-font-family: 'Oswald', serif;

$custom-title-font-size: clamp(1.7rem, 4vw, 4rem);
$display-3-font-size: clamp(0.8rem, 2vw, 3rem);
$body-2-font-size: clamp(0.7rem, 1.3vw, 1.45rem);

/* $custom-typography: mat.define-typography-config(
  $font-family: $custom-font-family,
  $headline-1: mat.define-typography-level(
    $custom-title-font-size,
    $font-family: $custom-title-font-family,
    $line-height: 1.2,
    $font-weight: 700,
  ),
  $headline-2: mat.define-typography-level(
    $display-3-font-size,
    $font-family: $custom-title-font-family,
    $line-height: 1.3,
    $font-weight: 600
  ),
  $body-2: mat.define-typography-level(
    $body-2-font-size,
    $line-height: 1.5,
    $font-weight: 400
  )
); */

$client-theme: mat.define-light-theme(
  (
    color: (
      primary: $client-primary,
      accent: $client-accent,
      warn: $client-warn,
      bg: $client-background,
    ),
    //typography: $custom-typography,
    density: 0,
  )
);

@include mat.all-component-themes($client-theme);
//@include mat.all-component-typographies($custom-typography);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

html {
  font-size: calc(15px + 0.390625vw);
}

.container-center-row,
.container-center-column,
.container-center-column-top,
.container-right-row,
.container-center-row-top {
  display: flex;
  height: 100%;
  width: 95%;
  margin: 0 auto;

  & > mat-card {
    margin-top: 20px;
  }
}

.container-center-row {
  justify-content: center;
  align-items: center;
}

.container-center-column {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-center-column-top {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container-right-row {
  justify-content: flex-end;
  align-items: center;
}

.container-center-row-top {
  justify-content: center;
  align-items: flex-start;
}

.right-align {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.gray-out {
  color: #888;
  opacity: 0.8;
}

.dark-gray-out {
  color: #333;
  opacity: 0.2;
}

.center {
  text-align: center;
}

.center-div {
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
}

.day {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.click-disabled {
  pointer-events: none;
}

.day-free {
  background-color: #d9f7be;
}

.day-reserved {
  background-color: #ff6c6c86;
}

.day-partially-reserved {
  background-color: #f9e79f;
}

.rounded {
  border-radius: 25px;
  padding: 20px;
  width: 200px;
  height: 150px;
}

.max-width-50 {
  max-width: 50%;

  @media only screen and (max-width: 1000px) {
    max-width: 100%
  }
}

.display-flex {
  display: flex !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

mat-error {
  color: red;
  font-size: 12px;
}

.mat-calendar-body-cell {
  font-size: 0.7rem;
}